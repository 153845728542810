import request from '@/utils/request'

// 获取银行卡列表
export function getBankList() {
    return request({
        url: '/api/sale/user/bank/list',
        method: 'post'
    })
}

// 解绑
export function untieBankAcco(data) {
    return request({
        url: '/api/sale/account/untieBankAcco',
        method: 'post',
        data: data
    })
}

// 查询当前支付通道及支持银行
export function getPayChannel(data) {
    return request({
        url: '/api/sale/sms/getPayChannel',
        method: 'POST',
        data
    })
}

// 新绑定银行卡发送验证码
export function bindSmsSign(data) {
  return request({
    url: '/api/sale/account/bindSmsSign',
    method: 'POST',
    data
  })
}

// 验证再次绑定银行卡验证码
export function bindVerifCodeForBank(data) {
  return request({
    url: '/api/sale/account/bindVerifCodeForBank',
    method: 'POST',
    data
  })
}

// 修改登录密码
export function editPwd(data) {
	return request({
    url: '/api/sale/securitycenter/editPwd',
    method: 'POST',
		data
	})
}

// 修改交易密码
export function editTradPwd(data) {
	return request({
    url: '/api/sale/securitycenter/editTradePwd',
    method: 'POST',
		data
	})
}

// 校验证件
export function inspection(data) {
  return request({
    url: '/api/sale/user/personal/identityInfo',
    method: 'POST',
	data
  })
}

//发送短信验证码
export function sendcode(data) {
  return request({
    url: '/api/sale/sms/routineSend',
    method: 'post',
	data
  })
}

//校验短信验证码
export function checkVerifCode(data) {
  return request({
    url: '/api/sale/sms/checkVerifCode',
    method: 'post',
	data
  })
}

// 重置交易密码
export function resetTradePassword(data) {
  return request({
    url: '/api/sale/user/personal/resetTradePwd',
    method: 'POST',
	data
  })
}

// 修改绑定手机号
export function editPhone(data) {
	return request({
    url: '/api/sale/customer/phone',
    method: 'POST',
		data
	})
}