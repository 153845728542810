// 手机号校验
export const phoneCheck = /^1[3|4|5|7|8][0-9]{9}$/
// 邮箱校验
export const emailCheck = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
// 邮编正则
export const zipCodeCheck = /^[0-9]{6}$/
// 身份证校验
export const idCardCheck = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/
// 登录密码正则
export const loginPWCheck = /(?=.*[a-zA-Z])(?=.*[\d])[\w\W]{6,16}/
// 交易密码
// 不连续
export const tradPWCheck1 = /^(?:(\d)(?!((?<=9)8|(?<=8)7|(?<=7)6|(?<=6)5|(?<=5)4|(?<=4)3|(?<=3)2|(?<=2)1|(?<=1)0){5})(?!\1{5})(?!((?<=0)1|(?<=1)2|(?<=2)3|(?<=3)4|(?<=4)5|(?<=5)6|(?<=6)7|(?<=7)8|(?<=8)9){5})){6}$/
// 不重复
export const tradPWCheck2 = /^(?=.*\d+)(?!.*?([\d])\1{5})[\d]{6}$/

// 银行卡号正则
export const bankCardCheck =  /^([1-9]{1})(\d{14,18})$/

// 中文正则
export const chineseCheck =  /^[\u4E00-\u9FA5]+$/
